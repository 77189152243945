exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/aboutUs.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-politicas-privacidad-jsx": () => import("./../../../src/templates/politicas-privacidad.jsx" /* webpackChunkName: "component---src-templates-politicas-privacidad-jsx" */),
  "component---src-templates-section-jsx": () => import("./../../../src/templates/section.jsx" /* webpackChunkName: "component---src-templates-section-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

